<template>
  <div class="choose-img-by-sentence-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="back-ground-img-area">
        <img :src="bgImg" alt="" />
      </div>
      <div class="pic-words-area" v-if="isShowshadow">
        <div class="pic-box">
          <div
            class="pic-item"
            v-for="(item, index) in sentenceList"
            :key="index"
          >
            <img :src="item.bgImg" alt="" v-if="index === currentId - 1" />
          </div>
        </div>
        <div class="words-box">
          <div
            class="hanzi-item"
            :class="{ noPadding: !item.hanzi }"
            v-for="(item, index) in sentenceList"
            :key="index"
            v-show="index === currentId - 1 && !lock"
          >
            <div class="pinyin sentence-hanzi font-pinyin-medium">
              {{ item.pinyin }}
            </div>
            <div class="sentence-hanzi font-hanzi-medium">
              {{ item.hanzi }}
            </div>
          </div>
        </div>
      </div>
      <div class="game-area">
        <div class="pic-area" @click="handleClickPic(null)"></div>
        <div class="side-menu-area">
          <div class="menu-item" v-for="(item, index) in picList" :key="index">
            <img
              :src="
                showIdList.indexOf(item.id) > -1
                  ? item.menuImg
                  : item.menuGrayImg
              "
              alt=""
              @click="handleClickPic(item.id)"
              :class="{ hasClick: showIdList.indexOf(item.id) > -1 }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "ChoosePicBySentenceGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    picList: {
      type: Array,
      require: true,
      default: () => [],
    },
    sentenceList: {
      type: Array,
      require: true,
      default: () => [],
    },
    titleInfo: {
      type: Object,
      require: false,
    },
  },
  components: {
    PageButton,
  },
  data() {
    return {
      isLastStep: false,
      showIdList: [],
      lock: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dragId: 0,
      step: 0,
      showHnaziId: 0,
      dropAvatarSocketInfo: {},
      clickSocketInfo: {},
      currentId: 0,
      isShowshadow: false,
      currentIndex: 0,
    };
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20049,
          data: { value },
          text: "ChoosePicBySentenceGame 点击menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("ClickSentenceMenu", ({ id }) => {
      this.handleClickPic(id, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("ClickSentenceMenu");
  },

  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickPic(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      if (id) {
        this.lock = true;
        console.log(id);
        this.currentId = id;
        this.isShowshadow = true;
        playCorrectSound(true, false);
        if (this.showIdList.indexOf(id) === -1) {
          this.showIdList.push(id);

          this.currentIndex++;
        }
      } else {
        this.lock = false;
        if (this.currentIndex === this.sentenceList.length) {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-img-by-sentence-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f3df;
    border-radius: 48px;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 30%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .back-ground-img-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .game-area,
    .pic-words-area {
      position: absolute;
      top: 0;
      //   left: 0;
    }
    .pic-words-area {
      width: 100%;
      height: 100%;
      .pic-box,
      .words-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .pic-box {
        background-color: rgba(159, 150, 150, 0.4);
        border-radius: 48px;
        .pic-item {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 58px;
          img {
            position: absolute;
            // top: 23%;
            // left: 24%;
            // width: 46%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 48px;
          }
        }
      }
      .words-box {
        .hanzi-item {
          width: 50%;
          margin: 0 auto;
          background: #cd4c3f;
          position: absolute;
          bottom: 7%;
          left: 25%;
          padding: 1% 1%;
          color: #fff;
          border-radius: 40px;
          .sentence-hanzi,
          .sentence-pinyin {
            text-align: center;
          }
        }
        .noPadding {
          display: none;
        }
      }
    }
    .game-area {
      width: 100%;
      height: 100%;
      display: flex;
      //   align-items: center;
      justify-content: flex-end;

      .pic-area {
        width: 100%;
        height: 100%;
        position: relative;
        flex: 4;
        cursor: pointer;
      }
      .side-menu-area {
        height: 70%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .menu-item {
          width: 80%;
          height: 22%;
          margin-top: 5%;

          img {
            box-sizing: border-box;
            // width: 75%;
            // border: 10px solid #224e96;
            // border-radius: 20px;

            width: 100%;
            height: 100%;
            border: 10px solid #cd4c3f;
            border-radius: 20px;
            object-fit: cover;

            cursor: pointer;
          }
          .hasClick {
            border: 10px solid #f4d737;
          }
        }
      }
    }
  }
}
</style>
